export const ja = {
  ja: {
    back: 'マイページ（商標一覧） へ戻る',
    attorneyMessage: '弁理士メッセージ',
    postMessage:
      'メッセージを送信しました。\n返信まで最大で1営業日お待ちください。',
    yes: 'はい',
    tabs: {
      attorneyMessage: '弁理士メッセージ',
      note: '通常１営業日以内に返信',
      tm: '商標',
      payment: 'お支払',
      registrationCard: '登録証',
    },
    processing: '処理中...',
    applicationInfo: {
      header: '商標情報',
      standardCharacter: '商標',
      nameOfTradeMarkInfo: '文字',
      pronunciation: '称呼',
      statusName: 'ステータス',
      referenceNumber: '整理番号',
      applicationNumber: '出願番号',
      attorneyOfficeName: '特許事務所',
      filingDate: '出願日',
      registrationNumber: '登録番号',
      registrationDate: '登録日',
      regFeeKindName: '納付期間',
      deadline: '次期納付日',
      applicant: {
        name: '出願人',
        address: '出願人住所',
      },
      document: '特許庁審査書類',
      class: '区分',
    },
    messages: {
      blankMessage:
        '受任後に弁理士からメッセージがございます。\n最大で1営業日お待ちください。',
      action1: {
        title: '次のステップ',
        line1: {
          message: '弁理士からの最新メッセージをご確認ください',
        },
        line2: {
          message: '出願書類をご確認ください',
          button: '出願書類を確認',
        },
        line3: {
          message: '出願を承認してください',
          button: '承認する',
        },
        dialog: {
          line1: '出願しますか？',
          line2: '・出願書類を特許庁に提出します。',
          line3: '・[はい]を押すと出願内容変更はできません。',
        },
        success:
          'ご確認ありがとうございます。\n{attorneyOffice} が提出準備に入ります。',
      },
      action2: {
        line1: '登録証の【送付方法】を選択してください',
        button1: 'PDFのみ ※2,000円割引',
        button2: '郵送 と PDF',
        dialog: {
          pdf: '登録証はPDFのみを希望しますか？',
          discount: '※ 2,200円（税込）が割引されます',
          mail: '登録証は郵送を希望しますか？',
        },
        successPdf:
          '登録証はPDFが選択されました。\n続いて[登録期間]を選択してください。',
        successMail: '続いて登録証の【郵送先住所】を入力してください。',
      },
      action3: {
        button1: '登録証の郵送先住所をご確認ください。',
        success: '続いて[登録期間]を選択してください。',
      },
      action4: {
        line1: '【登録期間】を選択してください',
        button1: '５年支払',
        button2: '１０年支払',
        dialog: {
          fiveYear: '5年を選択しますか？',
          tenYear: '10年を選択しますか？',
        },
        success: '「お支払い」から料金をお支払いください。',
      },
      action5: {
        line1: '【後期登録料納付】の支払い手続きお願いいたします。',
        button1: '５年後期支払い',
        dialog: {
          q: '後期5年支払いをしますか？',
        },
        success: '「お支払い」から料金をお支払いください。',
      },
      option1: '※ 詳しくは以下のメッセージをお読みください。',
      authorName: '{authorName}&nbsp;&nbsp;&nbsp;様',
      autoSend: 'システムメッセージ',
    },
    payment: {
      title: 'お支払い／領収書／請求書',
      pay: '支払',
      detail: '詳細',
      item: {
        name: '費用項目',
        class: '区分',
        taxRate: '税率',
        amount: '金額',
      },
      service_charge: '手数料',
      tax: '消費税',
      stamp_fee: '特許庁印紙代',
      withholding_tax: '源泉徴収税',
      total: '支払金額',
      taxIncluded: '税込',
      totalWithRaxRate: '支払金額（税込）',
      invoice: '請求書',
      receipt: '領収書',
      bankHeader: '振込先',
      bankName: '銀行',
      branchName: '支店',
      accountType: '口座種別',
      savingAccount: '普通預金',
      currentAccount: '当座預金',
      accountNumber: '口座番号',
      accountHolderName: '口座名義',
      bankPaymentExpiresDate: '振込期限',
      note: '【振込⼿数料】はお客様の負担でお願いいたします',
    },
    mailingAddress: {
      header: '登録証送付先住所',
      companyName: '法人名',
      isPersonal: '個人 / 個人事業主のため、法人名はない',
      lastName: '担当者 姓',
      firstName: '担当者 名',
      zipcode: '郵便番号',
      prefecture: '都道府県',
      city: '市区町村',
      street: '丁目・番地・建物名・部屋番号',
      phoneNumber: '電話番号',
      submit: '上記の住所に登録証を送付する',
    },
    registrationCard: {
      header: '登録証',
      recaMailReqTypeName: '送付方法',
      registrationCard: '登録証PDF',
      address: '郵送先住所',
      mailStatus: '郵送ステータス',
      download: 'ダウンロード',
    },
    systemError:
      '予期しないシステムエラーが発生しました。\nお手数をおかけしますがブラウザのリロードして再度お試しください。',
  },
};
