<template>
  <div class="mb-3 bg-white p-6 shadow-md sm:rounded">
    <!-- ユーザーアクション -->
    <!-- 出願書類の確認 -->
    <div v-if="userAction === 'Confirm_App'">
      <div class="mb-2 flex flex-col gap-y-2">
        <span class="font-bold">{{ t('messages.action1.title') }}</span>
        <div class="bg-bgGray flex items-center h-18 gap-y-2 px-5 py-3 rounded-lg flex-wrap">
          <div class="flex gap-x-2">
            <div>1.</div>
            <div>
              {{ t('messages.action1.line1.message') }}
            </div>
          </div>
        </div>
        <div class="bg-bgGray flex justify-between items-center gap-y-2 px-5 py-3 rounded-lg flex-wrap">
          <div class="flex gap-x-2 w-[300px]">
            <div>2.</div>
            <div>
              {{ t('messages.action1.line2.message') }}
            </div>
          </div>
          <PrimaryButton
            v-if="!isAppDocButtonEnabled"
            class="h-12 w-full sm:w-46"
            :title="t('messages.action1.line2.button')"
            @click="handleApplicationDocEvent"
          />
          <SecondaryButton
            v-if="isAppDocButtonEnabled"
            class="h-12 w-full sm:w-46"
            :title="t('messages.action1.line2.button')"
            @click="handleApplicationDocEvent"
          />
        </div>
        <div class="bg-bgGray flex justify-between items-center gap-y-2 px-5 py-3 rounded-lg flex-wrap">
          <div class="flex gap-x-2 w-[300px]">
            <div>3.</div>
            <div>
              {{ t('messages.action1.line3.message') }}
            </div>
          </div>
          <PrimaryButton
            class="h-12 w-full sm:w-46"
            :disabled="!isAppDocButtonEnabled"
            :title="t('messages.action1.line3.button')"
            @click="isAppModalVisibled = !isAppModalVisibled"
          />
        </div>
      </div>
      <div class="my-3 h-[1px] w-full bg-[#e9e9e9]" />
    </div>
    <!-- ./出願書類の確認 -->
    <!-- 登録証の送付方法 -->
    <div v-if="userAction === 'PDF_Mail'">
      <div class="mb-2 flex flex-col">
        <div class="mb-2 flex items-center">
          <div class="font-bold">
            {{ t('messages.action2.line1') }}
          </div>
        </div>
        <div class="flex items-center">
          <PrimaryButton
            class="h-16 w-52 sm:h-auto"
            :title="t('messages.action2.button1')"
            @click="handleRecaMailingMethodEvent('pdf_only')"
          />
          <PrimaryButton
            class="ml-4 h-16 w-52 sm:h-auto"
            :title="t('messages.action2.button2')"
            @click="handleRecaMailingMethodEvent('mail')"
          />
        </div>
      </div>
      <div class="mt-2 text-sm">
        {{ t('messages.option1') }}
      </div>
      <div class="my-3 h-[1px] w-full bg-[#e9e9e9]" />
    </div>
    <!-- 登録証郵送先 -->
    <div v-if="userAction === 'Mailing_Address'">
      <div class="mb-2 flex">
        <div class="flex items-center">
          <PrimaryButton
            class=""
            :title="t('messages.action3.button1')"
            @click="isMailAddrModalVisibled = !isMailAddrModalVisibled"
          />
        </div>
      </div>
      <div class="my-3 h-[1px] w-full bg-[#e9e9e9]" />
    </div>
    <!-- 5年10年 -->
    <div v-if="userAction === 'Five_Ten'">
      <div class="mb-2 flex flex-col">
        <div class="mb-1 font-bold">
          {{ t('messages.action4.line1') }}
        </div>
        <div class="flex items-center">
          <PrimaryButton
            class="w-48"
            :title="t('messages.action4.button1')"
            @click="handle0510ButtonEvent('five_year')"
          />
          <PrimaryButton
            class="ml-4 w-48"
            :title="t('messages.action4.button2')"
            @click="handle0510ButtonEvent('ten_year')"
          />
        </div>
        <div class="mt-2 text-sm">
          {{ t('messages.option1') }}
        </div>
      </div>
      <div class="my-3 h-[1px] w-full bg-[#e9e9e9]" />
    </div>
    <!-- 5年後期 -->
    <div v-if="userAction === 'IPayment'">
      <div class="mb-1 font-bold">
        {{ t('messages.action5.line1') }}
      </div>
      <div class="mb-2 flex flex-col">
        <div class="flex items-center">
          <PrimaryButton
            class="w-48"
            :title="t('messages.action5.button1')"
            @click="isIPayModalVisibled = true"
          />
        </div>
        <div class="mt-2 text-sm">
          {{ t('messages.option1') }}
        </div>
      </div>
      <div class="my-3 h-[1px] w-full bg-[#e9e9e9]" />
    </div>
    <!-- ./5年10年 -->
    <!-- ./ユーザーアクション -->
    <!-- メッセージ -->
    <template v-if="inquiriesStore.inquiries.length > 0">
      <div
        v-for="(inq, index) in inquiriesStore.inquiries"
        :key="inq.id"
      >
        <div
          v-if="index !== 0"
          class="my-3 h-[1px] w-full bg-[#e9e9e9]"
        />
        <div class="mb-1 flex items-center justify-between">
          <div class="flex items-center font-bold">
            <div
              v-if="inq.kind === 'customer'"
              class="flex items-center"
            >
              <div
                v-html="
                  t('messages.authorName', { authorName: inq.authorName })
                "
              />
            </div>
            <div
              v-if="inq.kind === 'patent_attorney'"
              class="flex items-center"
            >
              <div class="material-icons text-primary mr-1 !text-3xl">
                account_circle
              </div>
              <div>{{ inq.patentAttorneyName }}</div>
              <span class="text-grayAlpha60 ml-2 text-sm font-light">{{
                inq.role
              }}</span>
            </div>
            <div
              v-if="inq.kind === 'bot'"
              class="flex items-center"
            >
              <div class="material-icons text-primaryOrange60 mr-1 !text-3xl">
                push_pin
              </div>
              <div>{{ t('messages.autoSend') }}</div>
            </div>
          </div>
          <div class="text-grayAlpha60 text-sm">
            {{ inq.createdAt }}
          </div>
        </div>
        <!-- 本文 -->
        <div
          class="message-body mb-8"
          v-html="inq.content"
        />
        <!-- 添付ファイル -->
        <div
          v-for="(attachment, idx) in inq.attachments"
          :key="idx"
        >
          <button
            class="text-grayAlpha60 flex w-60 items-center rounded border border-solid border-[#e9e9e9] px-2 py-2 text-sm font-bold"
            @click="handleAttachmentEvent(attachment.url)"
          >
            <span class="material-icons mr-2">attach_file</span>
            <span class="truncate">{{ attachment.name }}</span>
          </button>
        </div>
      </div>
    </template>
    <template v-if="inquiriesStore.inquiries.length <= 0">
      <p v-html="t('messages.blankMessage')" />
    </template>
  </div>
  <!-- 出願 -->
  <Modal
    :is-visible="isAppModalVisibled"
    @close="isAppModalVisibled = !isAppModalVisibled"
  >
    <div class="px-4 pb-4 pt-4 sm:w-[420px]">
      <div>
        <div class="tracking-wide">
          <div class="mb-4 text-xl font-bold">
            {{ t('messages.action1.dialog.line1') }}
          </div>
          <div>{{ t('messages.action1.dialog.line2') }}</div>
          <div>{{ t('messages.action1.dialog.line3') }}</div>
        </div>
      </div>
      <div class="flex justify-end pt-8">
        <PrimaryButton
          :title="t('yes')"
          @click="handleAppModalSubmitEvent"
        />
      </div>
    </div>
  </Modal>
  <!-- 5年10年Modal -->
  <Modal
    :is-visible="is0510ModalVisibled"
    @close="is0510ModalVisibled = !is0510ModalVisibled"
  >
    <div class="p-4 sm:w-[480px]">
      <div class="mb-4 text-2xl font-bold">
        <span v-if="fiveYearOrTenYear === 'five_year'">
          {{ t('messages.action4.dialog.fiveYear') }}
        </span>
        <span v-if="fiveYearOrTenYear !== 'five_year'">
          {{ t('messages.action4.dialog.tenYear') }}
        </span>
      </div>
      <div class="flex justify-end pt-8">
        <PrimaryButton
          :title="t('yes')"
          :material-icon-name="''"
          @click="handle0510ModalSubmitEvent"
        />
      </div>
    </div>
  </Modal>
  <!-- 登録証の郵送方法 -->
  <Modal
    :is-visible="isRecaModalVisibled"
    @close="isRecaModalVisibled = !isRecaModalVisibled"
  >
    <div class="px-4 pb-4 pt-4 sm:w-[480px]">
      <div>
        <div class="tracking-wide">
          <div v-if="recaPdfORMail === 'pdf_only'">
            <div class="mb-4 text-xl font-bold">
              {{ t('messages.action2.dialog.pdf') }}
            </div>
            <div class="">
              {{ t('messages.action2.dialog.discount') }}
            </div>
          </div>
          <div v-if="recaPdfORMail === 'mail'">
            <div class="mb-4 text-xl font-bold">
              {{ t('messages.action2.dialog.mail') }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end pt-8">
        <PrimaryButton
          title="はい"
          :material-icon-name="''"
          @click="handleRecaModalSubmitEvent"
        />
      </div>
    </div>
  </Modal>
  <!-- 送付先住所の確認 -->
  <Modal
    :is-visible="isMailAddrModalVisibled"
    @close="isMailAddrModalVisibled = !isMailAddrModalVisibled"
  >
    <div class="px-4 pb-4 pt-4 sm:w-[480px]">
      <!-- ヘッダー -->
      <div
        class="border-borderGray mb-3 border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
      >
        {{ t('mailingAddress.header') }}
      </div>
      <div>
        <FormTextInput
          v-model="mailingAddressStore.companyName"
          class="mb-2"
          name="company_name"
          autocomplete="organization"
          :title="t('mailingAddress.companyName')"
          :required="true"
          :shows-required-label="true"
          :shows-error="false"
          :disabled="mailingAddressStore.isPersonal"
        />
        <div class="mb-4 flex items-center">
          <BaseCheckbox
            id="checkbox__isPersonal"
            v-model="mailingAddressStore.isPersonal"
            name="user[is_personal]"
          />
          <label
            for="checkbox__isPersonal"
            class="ml-2 cursor-pointer text-xs"
          >
            {{ t('mailingAddress.isPersonal') }}
          </label>
        </div>
        <div class="mb-2 flex">
          <FormTextInput
            v-model="mailingAddressStore.lastName"
            class="mr-2"
            name="lastName"
            autocomplete="family-name"
            :title="t('mailingAddress.lastName')"
            :required="true"
            :shows-required-label="true"
            :shows-error="false"
          />
          <FormTextInput
            v-model="mailingAddressStore.firstName"
            name="firstName"
            autocomplete="given-name"
            :title="t('mailingAddress.firstName')"
            :required="true"
            :shows-required-label="true"
            :shows-error="false"
          />
        </div>
        <!-- 郵便番号 -->
        <div class="mb-4 flex items-end">
          <FormTextInput
            v-model="mailingAddressStore.zipcode"
            class="mr-2"
            :title="t('mailingAddress.zipcode')"
            :required="true"
            :shows-required-label="true"
            :shows-error="false"
            name="postalCode"
            autocomplete="postal-code"
          />
        </div>
        <!-- ./郵便番号 -->
        <!-- 都道府県 市区町村 -->
        <div class="mb-3 flex">
          <FormTextInput
            v-model="mailingAddressStore.prefecture"
            class="mr-2"
            :title="t('mailingAddress.prefecture')"
            :required="true"
            :shows-required-label="true"
            :shows-error="false"
            name="prefecture"
            autocomplete="address-level1"
          />
          <!-- ./都道府県 -->
          <FormTextInput
            v-model="mailingAddressStore.city"
            :title="t('mailingAddress.city')"
            :required="true"
            :shows-required-label="true"
            :shows-error="false"
            name="city"
            autocomplete="address-level2"
          />
        </div>
        <FormTextInput
          v-model="mailingAddressStore.street"
          class="mb-3"
          :title="t('mailingAddress.street')"
          :required="true"
          :shows-required-label="true"
          :shows-error="false"
          autocomplete="address-level3"
        />
        <FormTextInput
          v-model="mailingAddressStore.phoneNumber"
          :title="t('mailingAddress.phoneNumber')"
          :shows-error="false"
          name="phoneNumber"
          placeholder="03-1234-5678"
          autocomplete="tel-national"
        />
        <div class="flex justify-end pt-4">
          <PrimaryButton
            :title="t('mailingAddress.submit')"
            @click="handleAddresSubmit"
          />
        </div>
      </div>
    </div>
  </Modal>
  <!-- ./送付先住所の確認 -->
  <!-- 後期5年 -->
  <Modal
    :is-visible="isIPayModalVisibled"
    @close="isIPayModalVisibled = !isIPayModalVisibled"
  >
    <div class="w-[380px] p-4">
      <div class="mb-4 text-2xl font-bold">
        {{ t('messages.action5.dialog.q') }}
      </div>
      <div class="flex justify-end pt-8">
        <PrimaryButton
          :title="t('yes')"
          :material-icon-name="''"
          @click="handleIPaymentModalSubmitEvent"
        />
      </div>
    </div>
  </Modal>
  <!-- スピナ -->
  <TheOverlayLoading
    v-if="processing"
    :message="t('processing')"
  />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useApplicationStore } from '@/composables/useApplicationStore';
import { useInquiriesStore } from '@/composables/useInquiriesStore';
import { useMailingAddressStore } from '@/composables/useMailingAddressStore';
import { usePaymentStore } from '@/composables/usePaymentStore';
import { useI18n } from 'vue-i18n';
import { ja } from '@/i18n/pages/app/applications/ja';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import SecondaryButton from '@/components/common/SecondaryButton.vue';

const toast = useToast();
const route = useRoute();
const applicationId = Number(route.params.id);
const isAppDocButtonEnabled = ref(false); // 「出願書類を開く」ボタンの活性状態
const isMailAddrModalVisibled = ref(false); // 「登録証郵送先」モータルの表示状態
const isAppModalVisibled = ref(false); // 「出願する」モーダルの表示状態
const is0510ModalVisibled = ref(false); // 「5年10年選択」モーダルの表示状態
const isIPayModalVisibled = ref(false); // 「後期5年」モーダルの表示状態
const isRecaModalVisibled = ref(false); // 「送付先住所」モーダルの表示状態
const recaPdfORMail = ref(''); // 郵送(mail) | PDF(pdf_only)
const fiveYearOrTenYear = ref(''); // 5年(five_year) | 10年(ten_year)
const inquiriesStore = useInquiriesStore();
const applicationStore = useApplicationStore();
const mailingAddressStore = useMailingAddressStore();
const paymentStore = usePaymentStore();
const processing = ref(false);
const { t } = useI18n({ messages: ja });

// 出願書類を開く
const handleApplicationDocEvent = () => {
  const url = `/app/mypage/applications/${applicationId}/html_export`;
  window.open(url);

  isAppDocButtonEnabled.value = true;
};

// 出願
const handleAppModalSubmitEvent = async () => {
  processing.value = true;

  try {
    await applicationStore.apply(applicationId);
    await applicationStore.getAppApplication(applicationId);
    await inquiriesStore.getInquiries(applicationId);

    toast.success(
      t('messages.action1.success', {
        attorneyOffice: applicationStore.attorneyOfficeName,
      }),
      { timeout: 20000 },
    );
  } catch {
    toast.error(t('systemError'), { timeout: 15000 });
  } finally {
    isAppModalVisibled.value = false;
    processing.value = false;
  }
};

// 登録証送付方法
const handleRecaMailingMethodEvent = (method: string) => {
  recaPdfORMail.value = method;
  isRecaModalVisibled.value = true;
};

// 5年10年選択
const handle0510ButtonEvent = async (year: string) => {
  fiveYearOrTenYear.value = year;
  is0510ModalVisibled.value = true;
};

// メッセージ内の添付ファイルを開く
function handleAttachmentEvent (url: string) {
  window.open(url);
}

// 5年10年Modalで「はい」クリック
const handle0510ModalSubmitEvent = async () => {
  processing.value = true;

  try {
    if (applicationStore.status === 'step_allowance') { // 登録LDの時
      await applicationStore.apply0510(applicationId, fiveYearOrTenYear.value);
    } else if (applicationStore.status === 'step_renewal_lead') { // 更新LDの時
      await applicationStore.applyRPayment(applicationId, fiveYearOrTenYear.value);
    } else {
      toast.error(t('systemError'), { timeout: 15000 });
    }
    await applicationStore.getAppApplication(applicationId);
    await paymentStore.fetch(applicationId);

    toast.success(t('messages.action4.success'), { timeout: 10000 });
  } catch {
    toast.error(t('systemError'), { timeout: 15000 });
  } finally {
    is0510ModalVisibled.value = false;
    processing.value = false;
  }
};

// 5年後期支払いモーダルで「はい」をクリックした時
const handleIPaymentModalSubmitEvent = async () => {
  processing.value = true;
  try {
    await applicationStore.applyIPayment(applicationId);
    await applicationStore.getAppApplication(applicationId);
    await paymentStore.fetch(applicationId);
  } catch {
    toast.success(t('messages.action5.success'), { timeout: 10000 });
  } finally {
    isIPayModalVisibled.value = false;
    processing.value = false;
  }
};

// 登録証PDF／郵送選択ダイアログで「はい」を押したとき
const handleRecaModalSubmitEvent = async () => {
  processing.value = true;

  try {
    await applicationStore.applyRecaPdfOrMail(
      applicationId,
      recaPdfORMail.value,
    );
    await applicationStore.getAppApplication(applicationId);

    if (recaPdfORMail.value === 'mail') {
      toast.success(t('messages.action2.successMail'), { timeout: 10000 });
    } else {
      toast.success(t('messages.action2.successPdf'), { timeout: 10000 });
    }
  } catch {
    toast.error(t('systemError'), { timeout: 15000 });
  } finally {
    isRecaModalVisibled.value = false;
    processing.value = false;
  }
};

// 登録証の郵送先送信
const handleAddresSubmit = async () => {
  processing.value = true;

  await mailingAddressStore.postAddress(applicationId);
  await applicationStore.getAppApplication(applicationId);
  await mailingAddressStore.getAdddess(applicationId);

  isMailAddrModalVisibled.value = false;
  toast.success(t('messages.action3.success'), { timeout: 20000 });

  processing.value = false;
};

const userAction = computed(() => {
  // 出願書類の確認
  // 条件
  //  [出願CK]
  if (applicationStore.status === 'step_application_final_confirmation') {
    return 'Confirm_App';
  }
  // 登録証の送付方法
  // 条件
  //  [登録LD]
  //  PDF/郵送選択なし
  //  5年10年選択なし
  if (
    applicationStore.status === 'step_allowance' &&
    !applicationStore.regFeeKind &&
    applicationStore.recaMailReqType === 'none'
  ) {
    return 'PDF_Mail';
  }
  // 登録証郵送先
  // 条件
  //  [登録LD]
  //  郵送
  //  郵送先住所の選択なし
  //  5年10年選択なし
  if (
    applicationStore.status === 'step_allowance' &&
    !applicationStore.regFeeKind &&
    applicationStore.recaMailReqType === 'mail' &&
    !mailingAddressStore.id
  ) {
    return 'Mailing_Address';
  }
  // ５年10年
  // 条件
  //  [登録LD]
  //  PDF/郵送
  //  5年10年 なし
  if (
    (applicationStore.status === 'step_allowance' ||
      applicationStore.status === 'step_renewal_lead') &&
    !applicationStore.regFeeKind
  ) {
    return 'Five_Ten';
  }

  // 後期5年
  //  [後期納付LD]
  //  登録料納付_5年後期 支払が存在しない
  if (
    applicationStore.status === 'step_installment_lead' &&
    !paymentStore.isIPaymentExists
  ) {
    return 'IPayment';
  }

  return '';
});
</script>
<style>
.message-body a {
  text-decoration: underline;
}
</style>
