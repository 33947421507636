import axios from 'axios';
import { NotFoundError } from '@/types/error';
import { defineStore } from 'pinia';

export interface Applicant {
  name: string;
  address: string;
}

export interface Klasses {
  klassNo: string;
  goodsAndService: string;
}

export interface Document {
  code: string;
  name: string;
  path: string;
}

type TrademarkType = 'text_type' | 'image_type' | '';

export interface TmApplication {
  id: number;
  standardCharacter: string;
  trademarkType: TrademarkType;
  nameOfTradeMarkInfo: string;
  pronunciation: string;
  imageUrl: string;
  status: string;
  statusName: string;
  referenceNumber: string;
  applicationNumber: string;
  filingDate: string;
  registrationNumber: string;
  registrationDate: string;
  regFeeKind: string;
  regFeeKindName: string;
  recaMailReqType: string;
  recaMailReqTypeName: string;
  assigneeName: string;
  attorneyOfficeId: string;
  attorneyOfficeName: string;
  stripeUserId: string;
  deadline: string;
  applicants: Applicant[];
  klasses: Klasses[];
  documents: Document[];
  createdAt: string;
  isPdfDownload: string;
  pdfFileUrl: string;
  mailStatus: string;
}

export const useApplicationStore = defineStore({
  id: 'applicationStore',
  state: (): TmApplication => ({
    id: 0,
    standardCharacter: '',
    trademarkType: '',
    nameOfTradeMarkInfo: '',
    pronunciation: '',
    imageUrl: '',
    status: '',
    statusName: '',
    referenceNumber: '',
    applicationNumber: '',
    filingDate: '',
    registrationNumber: '',
    registrationDate: '',
    regFeeKind: '',
    regFeeKindName: '',
    recaMailReqType: '',
    recaMailReqTypeName: '',
    assigneeName: '',
    attorneyOfficeId: '',
    attorneyOfficeName: '',
    stripeUserId: '',
    deadline: '',
    applicants: [],
    klasses: [],
    documents: [],
    createdAt: '',
    isPdfDownload: '',
    pdfFileUrl: '',
    mailStatus: '',
  }),
  actions: {
    async getAppApplication (appId: number) {
      this.$reset();

      const headers = {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
      };
      try {
        const response = await axios.get<TmApplication>(
          `/app/mypage/applications/${appId}.json`,
          headers,
        );

        this.id = response.data.id;
        this.standardCharacter = response.data.standardCharacter;
        this.trademarkType = response.data.trademarkType;
        this.nameOfTradeMarkInfo = response.data.nameOfTradeMarkInfo;
        this.pronunciation = response.data.pronunciation;
        this.imageUrl = response.data.imageUrl;
        this.status = response.data.status;
        this.statusName = response.data.statusName;
        this.referenceNumber = response.data.referenceNumber;
        this.filingDate = response.data.filingDate;
        this.registrationNumber = response.data.registrationNumber;
        this.applicationNumber = response.data.applicationNumber;
        this.registrationDate = response.data.registrationDate;
        this.regFeeKind = response.data.regFeeKind;
        this.regFeeKindName = response.data.regFeeKindName;
        this.recaMailReqType = response.data.recaMailReqType;
        this.recaMailReqTypeName = response.data.recaMailReqTypeName;
        this.assigneeName = response.data.assigneeName;
        this.attorneyOfficeId = response.data.attorneyOfficeId;
        this.attorneyOfficeName = response.data.attorneyOfficeName;
        this.stripeUserId = response.data.stripeUserId;
        this.deadline = response.data.deadline;
        response.data.applicants.forEach((applicant) => {
          this.applicants.push({
            name: applicant.name,
            address: applicant.address,
          });
        });
        response.data.klasses.forEach((klass) => {
          this.klasses.push({
            klassNo: klass.klassNo,
            goodsAndService: klass.goodsAndService,
          });
        });
        response.data.documents.forEach((document) => {
          this.documents.push({
            code: document.code,
            name: document.name,
            path: document.path,
          });
        });
        this.createdAt = response.data.createdAt;
        this.isPdfDownload = response.data.isPdfDownload;
        this.pdfFileUrl = response.data.pdfFileUrl;
        this.mailStatus = response.data.mailStatus;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
          throw new NotFoundError();
        }
        throw e;
      }
    },
    async apply (appId: number) {
      await axios.post(
        `/app/mypage/applications/${appId}/inquiries/update_status.json`,
      );
    },
    // 登録料納付_5年または10年
    async apply0510 (appId: number, feeKind: string) {
      const option = {
        fee_kind: feeKind,
      };
      await axios.post(
        `/app/mypage/applications/${appId}}/payments/create_payment.json`,
        option,
      );
    },
    async applyRecaPdfOrMail (appId: number, recaMailReqType: string) {
      const option = {
        reca_mail_req_type: recaMailReqType,
      };

      await axios.patch(
        `/app/mypage/applications/${appId}/update_reca_mail_req_type.json`,
        option,
      );
    },
    // 登録料納付_5年後期
    async applyIPayment (appId: number) {
      await axios.post(
        `/app/mypage/applications/${appId}/payments/create_installment_payment.json`,
      );
    },
    // 更新料納付_5年10年
    async applyRPayment (appId: number, feeKind: string) {
      const option = {
        fee_kind: feeKind,
      };
      await axios.post(
        `/app/mypage/applications/${appId}/payments/create_renewal_payment.json`,
        option,
      );
    },
  },
});
