import { defineStore } from 'pinia';
import { Maybe } from '@/lib/types';
import { NotificationItem } from '../types/notification-item.types';

/**
 * お知らせのリストを保持する状態管理を取得
 * @param attorneyOfficeId アサインされている弁理士事務所ID。未アサインの場合は`null`。これにより出すべきお知らせをフィルタリングする。
 */
export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    attorneyOfficeId: null as Maybe<number>,
    allNotifications: [
      {
        id: 7,
        source: 'cotobox',
        createdDate: '2024/07/29',
        title: 'タイトル／お盆期間中は、カレンダー通り営業いたします',
        body: `
        平素よりCotoboxをご利用いただき、誠にありがとうございます。 <br>
        <br>
        お盆期間中、Cotobox、提携事務所ともに、通常通り営業いたします。<br>
        <br>
        ＜営業時間＞ 10:00 ～ 17:00<br>
        ＜定休日＞ 土曜日・日曜日・祝祭日<br>
        <br>
        なお、以下の日程は、祝日の振替日であり、Cotoboxも休業いたしますのでご注意ください。<br>
        <br>
        ・2024年8月11日（祝）の振替休日：2024年8月12日（月）
        <br>
        Cotoboxの検索・マイページ・メッセージ機能等は通常通りご利用いただけます。<br>
        ご不便をおかけいたしますが、何卒ご理解たまわりますようお願い申し上げます。<br>
        <br>
        Cotoboxサポート
        `,
      },
      {
        id: 6,
        source: 'cotobox',
        createdDate: '2024/04/22',
        title: 'ゴールデンウィークはカレンダー通り営業いたします',
        body: `
        平素よりCotoboxをご利用いただき、誠にありがとうございます。<br>
        <br>
        ゴールデンウィークは、カレンダー通り営業いたします。<br>
        <br>
        ＜営業時間＞ 10:00 ～ 17:00 <br>
        ＜定休日＞ 土曜日・日曜日・祝祭日 <br>
        <br>
        連休中にサポートチームへチャットやメールでいただいたお問い合わせ、<br> 
        並びに、弁理士メッセージ機能による提携事務所へのお問い合わせは、営業日に順次対応いたします。<br> 
        <br>
        また、連休明けの営業日にはお問い合わせが集中し、通常より回答にお時間を要する場合がございます。<br>
        <br>
        なお、Cotoboxの検索・マイページ・メッセージ機能等は通常通りご利用いただけます。<br>
        <br>
        ご不便をおかけいたしますが、何卒ご理解たまわりますようお願い申し上げます。<br>
        Cotoboxサポート<br>
        `,
      },
      {
        id: 5,
        source: 'cotobox',
        createdDate: '2023/12/11',
        title: '年末年始休業のお知らせ',
        body: `
        平素よりCotoboxをご利用いただき、誠にありがとうございます。<br>
        <br>
        誠に勝手ながら、下記期間は、Cotoboxのサポートチームによる各種サポート、並びに、提携事務所による対応を休業いたします。<br>
        なお、Cotoboxの検索・マイページ・メッセージ機能等は通常通りご利用いただけます。<br>
        <br>
        期間中はご不便をお掛けしますが、何卒ご理解たまわりますようお願い申し上げます。<br>
        <br>
        ＜休業期間＞<br>
        2023年12月29日（金）～ 2024年1月3日（水）<br>
        <br>
        ※2024年1月4日（木）より平常通り営業いたします。<br>
        ※休業期間中にチャットおよびメールでいただいたご連絡は、営業日に順次返信いたします。お問い合せの集中により、通常よりも回答までお時間をいただく場合がございます。あらかじめご了承ください。<br>
        `,
      },
      {
        id: 4,
        source: 'cotobox',
        createdDate: '2023/11/01',
        title: 'Cotobox利用規約改定のお知らせ',
        body: `
          平素よりCotoboxをご利用いただき誠にありがとうございます。<br>
          <br>
          当社は、この度、Cotoboxの利用規約を次のとおり改定いたしますことを、お知らせいたします。<br>
          <br>
          ■改定日<br>
          2023年11月1日<br>
          <br>
          ■主な改定内容：<br>
          - 利用規約の法的性質の整序（規約が定型約款（民法第548条の2）に該当する旨等を明確化）<br>
          - サービス内容の説明を具体化<br>
          - 会員登録時、サービス利用時の禁止事項の追加・変更<br>
          - メールマガジン等の配信についての規定を追加<br>
          - 当社の業務に必要となる情報を当社が登録専門家から取得することができる旨を明確化<br>
          <br>
          <br>
          規約本文は下記ページにてご確認くださいませ。<br>
          ▶ <a href="https://cotobox.com/terms/" target="_blank" class="text-primary underline">https://cotobox.com/terms/</a> ◀<br>
          <br>
          本件に関するお問い合わせはホームページ右下のチャット、もしくは、お電話にてCotoboxサポートチームへご連絡ください。<br>
          <br>
          何卒よろしくお願い申し上げます。<br>
          `,
      },
      {
        id: 3,
        source: 'authense',
        createdDate: '2022/10/18',
        title: 'Authense Professional Groupへの参画と改称について',
        body: `はつな弁理士法人は、この度Authense Professional Groupに参画し、Authense弁理士法人へと改称する運びとなりましたことをお知らせいたします。<br>
            <br>
            はつな弁理士法人は、これまで「全てのビジネスに知財を」というスローガンのもと、ITやAIを積極的に活用しながら、スタートアップや中小企業を中心とするクライアントに対し知的財産分野のサービスを提供してまいりました。<br>
            <br>
            Authense Professional Groupは、弁護士ドットコム株式会社を創業した元榮太一郎が代表弁護士を務めるAuthense法律事務所をはじめ、社労士法人やコンサルティング会社といったプロフェッショナル集団から構成されるグループであり、企業や経営者が抱える問題の解決に尽力しています。<br>
            <br>
            弊所は、Authense弁理士法人としてAuthense Professional Groupに参画することにより、グループを構成する他の企業と連携し、クライアントに寄り添ったより一層先進的で質の高いサービスを提供してまいります。<br>
            <br>
            本件に関し、詳細は下記ページよりご覧ください。<br>
            <a href="https://www.authense.jp/press/2547/" target="_blank" class="text-primary underline">https://www.authense.jp/press/2547/</a>
          `,
      },
      {
        id: 2,
        source: 'cotobox',
        createdDate: '2023/03/03',
        title: '料金改定と新機能追加のお知らせ',
        body: `平素よりCotoboxをご利用いただきありがとうございます。<br>
            Cotoboxサポートチームでございます。<br>
            このたび、Cotoboxを通じて特許庁手続きをご依頼いただく際に、各ユーザーさま担当の弊社提携事務所が申し受ける各種手数料を改定いたします。<br>
            <br>
            また、基本料金でのサービス内容を変更し、登録証をPDFデータにてダウンロードできる機能を追加実装いたします。<br>
            <br>
            詳細は下記ページにてご確認くださいませ。<br>
            <a href="https://support.cotobox.com/news202303" target="_blank" class="text-primary underline">https://support.cotobox.com/news202303</a>
            <br>
            <br>
            日頃よりご愛顧いただいているユーザーさまには誠に恐れ入りますが、何卒ご理解賜りますようお願い申し上げます。<br>
            <br>
            本件に関するお問い合わせは、ホームページ右下のチャット、もしくはお電話にてCotoboxサポートチームへご連絡ください。<br>
            <br>
            何卒よろしくお願い申し上げます。
          `,
      },
      {
        id: 1,
        source: 'kx',
        createdDate: '2023/04/02',
        title: 'KX特許事務所での新規受付停止について',
        body: `平素より大変お世話になっております。<br>
          KX特許事務所でございます。<br>
          <br>
          誠に勝手ながら諸般の事情により、弊所のCotoboxにおける新規相談受付を、2023年5月13日以降停止させていただくこととなりました。<br>
          <br>
          これまで長年にわたりお付き合いいただきましたこと、深く感謝申し上げます。<br>
          <br>
          なお、cotobox株式会社が提供する商標登録サービス「Cotobox」の商標管理機能や、新規出願のサービスは今後も変わりなくご利用いただけます。<br>
          また、弊所にて受任いたしました既存の案件につきましては、以下の通り対応いたします。<br>
          <br>
          1. お申し込み中の商標について<br>
          商標登録出願の依頼に関する契約条件に基づき、2023年5月13日以降も引き続き対応いたします。<br>
          <br>
          2. 登録済みの商標について<br>
          更新時期が近づきましたら、通常通りご登録のメールアドレスへCotoboxから更新通知が届きます。<br>
          更新手続きは、Cotoboxのマイページ上で、Cotobox提携の別事務所へご依頼いただけます。<br>
          <br>
          3. 海外出願や、権利移転、住所変更のご依頼<br>
            Cotobox提携の別事務所へご依頼いただけます。<br>
            ご希望の場合は、マイページ内弁理士メッセージ、または、Cotoboxサポートチームまでご連絡くださいませ。<br>
          <br>
          4. 2023年5月13日以降の新規出願ご依頼<br>
            これまで通り、Cotoboxウェブサイトよりお申し込みいただけます。<br>
            その際、お手続きを受付いたしますのは、Cotobox提携の別事務所でございます。<br>
          <br>
          <br>
          何かご質問等ございましたら、お気軽にお問い合わせください。<br>
          <br>
          何卒よろしくお願い申し上げます。<br>
          <br>
          --<br>
          KX特許事務所<br>
          〒107-0052 東京都港区赤坂9-6-28-505<br>
          営業時間：平日9:00-17:30`,
      },
    ] as NotificationItem[],
  }),
  getters: {
    notifications: (state) => {
      // 弁理士事務所未アサイン
      if (state.attorneyOfficeId === null) {
        return state.allNotifications.filter((e) => e.source === 'cotobox');
      }
      // はつな or Authense
      if ([1, 4].includes(state.attorneyOfficeId)) {
        return state.allNotifications.filter(
          (e) => e.source === 'cotobox' || e.source === 'authense',
        );
      }
      // KX
      if (state.attorneyOfficeId === 3) {
        return state.allNotifications.filter(
          (e) => e.source === 'cotobox' || e.source === 'kx',
        );
      }

      return state.allNotifications.filter((e) => e.source === 'cotobox');
    },
  },
});
