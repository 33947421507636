<script setup lang="ts">
import { inject, ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplyFormStore } from '@/composables/useApplyForm';
import { UserDataStore } from '@/composables/useUserStore';
import { useRoute, useRouter } from 'vue-router';
import { KlassId } from '@/types';
import {
  APIClientKey,
  ApplyFormKey,
  KlassSelectStoreKey,
  TrademarkSearchStoreKey,
  UserDataKey,
} from '@/composables/injectionKeys';
import { usePriceCalculator } from '@/composables/usePriceCalculator';
import AdditionalOptions from '@/components/applyForm/AdditionalOptions.vue';
import AgreeTerms from '@/components/applyForm/AgreeTerms.vue';
import ApplyFormHeader from '@/components/applyForm/ApplyFormHeader.vue';
import ApplicantForm from '@/components/applyForm/ApplicantForm.vue';
import ApplyInterview from '@/components/applyForm/ApplyInterview.vue';
import PriceConfirm from '@/components/applyForm/PriceConfirm.vue';
import TrademarkInfo from '@/components/applyForm/TrademarkInfo.vue';
import DefaultButton from '@/components/common/DefaultButton.vue';
import Modal from '@/components/common/Modal.vue';
import CreditCardPayment from '@/components/common/CreditCardPayment.vue';
import { APIClientInterface, PaymentType } from '@/api/APIClientInterface';
import { useToast } from 'vue-toastification';
import ChannelService from '@/lib/channelService';
import { KlassSelectStore } from '@/composables/useKlassSelect';
import { TrademarkSearchStore } from '@/composables/useTrademarkSearch';
import HatsunaLogo from '@/assets/images/logos/logo_hatsuna.jpg';
import KXLogo from '@/assets/images/logos/logo_kx.jpg';
import AuthenseLogo from '@/assets/images/logos/logo_authense.png';
import PaySteps from '../applyForm/PaySteps.vue';
import CardBrandsImage from '@/assets/images/icons/cardBrands.png';
import SectionWrapper from '../common/SectionWrapper.vue';
import PricePreview from '../apply/PricePreview.vue';

const componentName = 'RegistTrademarkPage';

const { t } = useI18n({
  messages: {
    ja: {
      priceAsideTitle: 'お見積もり',
      priceAsideDescription:
        '※ 審査の結果、登録可能であった場合、登録費用をお支払いいただきます。\n審査結果が登録不可であった場合、反論および補正の機会が与えられます。意見書の作成費用は55,000円（税込）から、手続補正書の作成費用は11,000円（税込）からです。詳しくは{1}',
      paymentMethod: 'お支払い方法',
      creditCardTitle: 'クレジットカード',
      creditCardDescription:
        '下記のカード会社がご利用できます。\n領収書は、マイページの「お支払い情報」ページより発行可能です。\n※プリペイドカード（またはギフトカード）によるお支払いは非推奨です。',
      trademarkInfo: '出願情報の確認',
      additonalOption: 'オプション',
      applicantForm: '出願人の確認',
      applyInterview: '担当弁理士への連絡事項',
      estimateButtonApplication: '見積書（出願時）',
      estimateButtonRegistration5y: '見積書（登録時 ５年選択）',
      estimateButtonRegistration10y: '見積書（登録時 10年選択）',
      saveTitle: '検索条件を保存する',
      saveSucceeded: '保存しました。',
      saveViewSavedItems: 'マイページで確認する',
      anchorText: 'こちら',
      note: '※申し込み後に、担当弁理士からCotoboxのメッセージ機能を通じてご連絡がございます。',
      back: '戻る',
      submit: '支払いに進む',
      paymentModal: '出願費用',
      commentPrefixForLittleChance: '＜システム追加文言：類似の商標あり＞',
      error: {
        alreadySaved: 'すでに保存されています。',
        unagreed: '依頼契約書の同意にチェックを入れてください。',
        unfilled: '住所と電話番号を入力してください。',
        addressIsEmpty: '住所に空欄が含まれているようです。',
        addressIsTooLong: '住所の文字が長すぎます。',
        unsearched:
          '類似商標の検索が行われていません。前のページに戻って、検索を行ってください。',
        estimate:
          '見積書の生成に失敗しました。恐れ入りますがチャットからご連絡ください。',
        updateUser:
          'ユーザー情報の更新に失敗しました。恐れ入りますがもう一度お試しください。',
        pleaseReflesh:
          '情報の取得に失敗しました。恐れ入りますが画面をリロードしてください。',
      },
    },
    en: {
      priceAsideTitle:
        'Total amount until application registration is completed (for 5 years of registration)',
      priceAsideDescription:
        '*If the results of the screening process indicate that registration is possible, a registration fee will be charged.\n' +
        '*Payment is required after the screening is completed. For more information, please contact {0}\n' +
        'If the trademark registration is not registered as a result of the examination, the applicant will be given an opportunity to respond and amend the trademark. For more information, see {1}',
      paymentMethod: 'Payment Method',
      creditCardTitle: 'credit card',
      creditCardDescription:
        'The following credit card companies are accepted.\n' +
        'Receipts can be issued from the "Payment Information" page of My Page.\n' +
        '*Prepaid card (or gift card) payments are deprecated.',
      trademarkInfo: 'Confirm Application Information',
      additonalOption: 'option',
      applicantForm: 'Verification of applicant',
      applyInterview: 'Contact the patent attorney in charge',
      estimateButtonApplication: 'See the book (when you make a wish)',
      estimateButtonRegistration5y:
        'Estimate (5 years selected at registration)',
      estimateButtonRegistration10y:
        'Estimate (10 years selected at registration)',
      saveTitle: 'Save search criteria',
      saveSucceeded: 'Saved in.',
      saveViewSavedItems: 'Check in My Page',
      anchorText:
        'this way (direction close to the speaker or towards the speaker)',
      note: '*After placing an order, your patent attorney will send you a notice of acceptance.',
      back: 'return',
      submit: 'Request an Application',
      paymentModal: 'Volunteer Fee',
      commentPrefixForLittleChance: '＜システム追加文言：類似の商標あり＞',
      error: {
        alreadySaved: 'Already saved.',
        unagreed: 'Please check the box to agree to the request agreement.',
        unfilled: 'Please enter your address.',
        addressIsEmpty: 'The address appears to contain blank spaces.',
        addressIsTooLong: 'The letters in the address are too long.',
        unsearched:
          'A search for similar trademarks has not been performed. Please return to the previous page to search.',
        estimate: 'Generating a quotation failed. Please contact us via chat.',
        updateUser: 'Failed to update user information. Please try again.',
        pleaseReflesh:
          'Failed to retrieve information. Please reload the screen.',
      },
    },
    zh: {
      priceAsideTitle: '完成申请注册前的总金额（注册5年）。',
      priceAsideDescription:
        '*如果评估结果显示可以注册，将收取注册费。\n' +
        '*筛查结束后需要支付费用。更多信息，见{0}。\n' +
        '*如果审查结果是不可注册的商标注册，你将有机会进行反驳和修改。更多信息，见{1}。',
      paymentMethod: '付款方式。',
      creditCardTitle: '信用卡',
      creditCardDescription:
        '接受以下信用卡公司。\n收据可以从 "我的页面 "的 "付款信息 "页面上发出。\n*预付卡（或礼品卡）付款已被废弃。',
      trademarkInfo: '检查申请信息',
      additonalOption: '选择。',
      applicantForm: '申请人的身份',
      applyInterview: '负责的专利律师的联系信息',
      estimateButtonApplication: '見積書（出願時）',
      estimateButtonRegistration5y: '报价（注册时选择5年）',
      estimateButtonRegistration10y: '报价（登记时选择10年）',
      saveTitle: '保存搜索标准',
      saveSucceeded: '保存在。',
      saveViewSavedItems: '在我的页面上查看。',
      anchorText: '这样(方向靠近扬声器或朝向扬声器)',
      note: '*订购后，你的专利律师将向你发出接受通知。',
      back: '返回',
      submit: '要求提供申请',
      paymentModal: '出願費用',
      commentPrefixForLittleChance: '＜システム追加文言：類似の商標あり＞',
      error: {
        alreadySaved: '已经存储。',
        unagreed: '勾选方框，同意请求协议。',
        unfilled: '地址。',
        addressIsEmpty: '该地址似乎含有空白。',
        addressIsTooLong: '地址中的字母太长了。',
        unsearched: '没有进行类似商标的搜索。请返回前一页，进行搜索。',
        estimate: '生成报价单失败。请通过聊天与我们联系。',
        updateUser: '更新用户信息失败。请再试一次。',
        pleaseReflesh: '检索信息失败。请重新加载屏幕。',
      },
    },
  },
});

const toast = useToast();
const route = useRoute();
const router = useRouter();

const apiClient = inject(APIClientKey) as APIClientInterface;

const {
  trademarkType,
  standardCharacter,
  imageToken,
  imageText,
  textSearchResults,
  imageSearchResults,
  save,
} = inject(TrademarkSearchStoreKey) as TrademarkSearchStore;

const { klassesForSubmit } = inject(KlassSelectStoreKey) as KlassSelectStore;

const {
  state: userData,
  authenticated,
  isAttorneyCorporation,
  loadUser,
  updateUser,
  assignAttorneyOffice,
} = inject(UserDataKey) as UserDataStore;

const {
  state: applyFormState,
  klassCount,
  clearState,
} = inject(ApplyFormKey) as ApplyFormStore;

const premiumPlan = computed(() => applyFormState.premiumPlan);
const speedPlan = computed(() => applyFormState.speedPlan);
const isPersonal = computed(() => userData.isPersonal);
const registPeriod = ref<'5years' | '10years'>('5years');
const is10yearRegistration = computed(() => registPeriod.value === '10years');

const {
  applyUsageFee,
  applyPrintFee,
  applyTax,
  applySubTotalFee,
  premiumPlanFee,
  speedPlanFee,
} = usePriceCalculator({
  klassCount,
  premiumPlan,
  speedPlan,
  authenticated,
  isPersonal,
  isAttorneyCorporation,
  is10yearRegistration,
});

onMounted(async () => {
  // ページを離れようとしたら確認ダイアログを出す
  window.addEventListener('beforeunload', onBeforeUnloadHandler);

  // 弁理士事務所を割り当て
  await assignAttorneyOffice().catch(() => {
    toast.error(t('error.pleaseReflesh'));
  });

  // 最新のユーザー情報を状態管理に読み込む
  const loadError = await loadUser();
  if (loadError) {
    window.location.href = '/app/login';
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', onBeforeUnloadHandler);
});

function onBeforeUnloadHandler (event: BeforeUnloadEvent) {
  // ページを離れようとしたら確認ダイアログを出す
  event.preventDefault();
  event.returnValue = '';
}

async function onEstimateButtonClick (paymentType: PaymentType) {
  const klasses = (Object.keys(klassesForSubmit.value) as KlassId[])
    .map((klassId) => {
      const klassGoodsServiceIds = klassesForSubmit.value[klassId] as string[];
      return klassGoodsServiceIds.map((goodsServiceId) => ({
        klassId,
        goodsServiceId,
      }));
    })
    .flat();
  const { data, error } = await apiClient.generateEstimate({
    paymentType,
    trademarkType: trademarkType.value,
    klasses,
    klassCount: klassCount.value,
    standardCharacter:
      trademarkType.value === 'text'
        ? standardCharacter.value
        : imageText.value,
    imageToken: imageToken.value,
    premiumPlan: applyFormState.premiumPlan,
    speedPlan: applyFormState.speedPlan,
  });
  if (data) {
    window.open(`/app/estimates/${data}`);
  } else {
    if (error && error === 'unauthorized') {
      location.href = '/app/login';
    } else {
      toast.error(t('error.estimate'));
    }
  }
}

async function onSaveButtonClick () {
  const { data, error } = await save();
  if (error === 'unauthorized') {
    location.href = '/app/login';
  } else if (error === 'already-exists') {
    return toast.error(t('error.alreadySaved'));
  } else if (error !== undefined) {
    return toast.error('保存に失敗しました。');
  } else if (data !== undefined) {
    router.push({
      name: 'mypage',
      query: {
        status: 'saved',
      },
    });
  }
}

const showsPaymentModal = ref(false);

async function onSubmitButtonClick () {
  if (!applyFormState.termsAgreed) {
    return toast.error(t('error.unagreed'));
  }

  const { country, prefecture, city, street } = userData.address;
  const phoneNumber = userData.phoneNumber;
  if (!country || !prefecture || !city || !street || !phoneNumber) {
    return toast.error(t('error.unfilled'));
  }

  if (
    !prefecture.replace(/\s+/g, '').length ||
    !city.replace(/\s+/g, '').length ||
    !street.replace(/\s+/g, '').length
  ) {
    return toast.error(t('error.addressIsEmpty'));
  } else if (
    prefecture.replace(/\s+/g, '').length > 50 ||
    city.replace(/\s+/g, '').length > 100 ||
    street.replace(/\s+/g, '').length > 100
  ) {
    return toast.error(t('error.addressIsTooLong'));
  }

  if (
    trademarkType.value === 'text' &&
    Object.keys(textSearchResults.value).length === 0
  ) {
    return toast.error(t('error.unsearched'));
  } else if (
    trademarkType.value === 'image' &&
    Object.keys(imageSearchResults.value).length === 0
  ) {
    return toast.error(t('error.unsearched'));
  }

  // 類似商標がある場合はコメント追加する
  if (route.query.isLittleChanceConfirmed === 'true') {
    const commentPrefix = t('commentPrefixForLittleChance');
    if (!applyFormState.comment.includes(commentPrefix)) {
      applyFormState.comment = commentPrefix + '\n' + applyFormState.comment;
    }
  }

  const error = await updateUser();
  if (error) {
    if (error === 'unauthorized') {
      location.href = '/app/login';
      return;
    } else {
      return toast.error(t('error.updateUser'));
    }
  }

  showsPaymentModal.value = true;
}

function onBackButtonClick () {
  router.push({
    name: trademarkType.value === 'image' ? 'ImageSearchPage' : 'SearchPage',
    query: route.query,
  });
}

function onPayComplete (isBankSelect: boolean, paymentId: number) {
  if (!userData.attorneyOffice) {
    return;
  }

  const { id, name } = userData.attorneyOffice;
  ChannelService.updateUser(
    {
      profile: {
        purchased: true,
        attorneyOfficeId: String(id),
        attorneyOfficeName: name.ja,
      },
      // @ts-ignore
    },
    (error, _user) => {
      if (error) {
        console.error(error);
      }
    },
  );

  if (window.dataLayer) {
    const items: {
      item_name: string;
      item_id: string;
      price: number;
      quantity: number;
    }[] = [];
    items.push({
      item_name: 'Trademark Application',
      item_id: 'trademark_application',
      price: applyUsageFee.value + applyPrintFee.value,
      quantity: 1,
    });
    if (applyFormState.premiumPlan) {
      items.push({
        item_name: 'Full Support Plan',
        item_id: 'full_support_plan',
        price: premiumPlanFee.value,
        quantity: 1,
      });
    }
    if (applyFormState.speedPlan) {
      items.push({
        item_name: 'Speed Plan',
        item_id: 'speed_plan',
        price: speedPlanFee.value,
        quantity: 1,
      });
    }

    window.dataLayer.push({ purchased: true });
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currency: 'JPY',
        transaction_id: `${Date.now()}`,
        value: applySubTotalFee.value,
        tax: applyTax.value,
        items,
      },
    });
  }
  clearState();
  showsPaymentModal.value = false;

  const payment_type = isBankSelect ? 'bank' : 'credit';

  router.push({
    name: 'ThanksPage',
    params: { id: paymentId },
    query: { payment_type },
  });
}
</script>

<template>
  <div
    class="bg-bgGray pt-8"
    :class="`${componentName}`"
  >
    <div :class="[`${componentName}__content`, `inner`, `mobileInner`]">
      <ApplyFormHeader :class="`${componentName}__header`" />
      <div :class="`${componentName}__aside`">
        <SectionWrapper
          class="mb-6 pt-4"
          :title="t('priceAsideTitle')"
        >
          <PricePreview
            :regist-period="registPeriod"
            @update:regist-period="($e) => (registPeriod = $e)"
          />
          <div class="mt-6">
            <DefaultButton
              v-if="$device.isDesktop"
              class="hover:bg-bgGray mb-4 !w-full"
              :title="t('estimateButtonApplication')"
              @click="onEstimateButtonClick('application')"
            />
            <DefaultButton
              v-if="$device.isDesktop"
              class="hover:bg-bgGray mb-4 !w-full"
              :title="t('estimateButtonRegistration5y')"
              @click="onEstimateButtonClick('registration_5yr')"
            />
            <DefaultButton
              v-if="$device.isDesktop"
              class="hover:bg-bgGray mb-4 !w-full"
              :title="t('estimateButtonRegistration10y')"
              @click="onEstimateButtonClick('registration_10yr')"
            />
            <DefaultButton
              class="hover:bg-bgGray mb-4 !w-full"
              :title="t('saveTitle')"
              @click="onSaveButtonClick"
            />
          </div>
          <div class="whitespace-pre-line text-sm">
            <div v-if="$device.isMobile">
              ※
              見積書が必要な場合は、お手数ですがパソコンでご利用いただくようお願い申し上げます。
            </div>
            <i18n-t keypath="priceAsideDescription">
              <a
                class="text-primary underline"
                href="https://support.cotobox.com/process-registration-permission"
                target="_blank"
              >
                {{ t('anchorText') }}
              </a>
              <a
                class="text-primary underline"
                href="https://support.cotobox.com/process-notice-reasons-for-rejection"
                target="_blank"
              >
                {{ t('anchorText') }}
              </a>
            </i18n-t>
          </div>
        </SectionWrapper>
        <SectionWrapper
          class="mb-6 sm:mb-0"
          :title="t('paymentMethod')"
        >
          <div class="p-0">
            <div class="text-grayAlpha80 mb-6 text-sm">
              クレジットカード、または銀行振込をご利用いただけます。<br>
              ※
              プリペイドカード（またはギフトカード）によるお支払いは非推奨です。<br>
              ※ 領収書は、マイページの「お支払い情報」ページより発行可能です。
            </div>
            <div class="text-textBlack mb-1 font-bold">
              クレジットカード
            </div>
            <div class="text-grayAlpha80 mr-6 text-sm">
              ご利用できるクレジットカードは、<br class="hidden sm:block">
              VISA・Mastercard・American Express・JCB・Discover
              Card・ダイナースクラブです。<br>
              なお、カード番号などのクレジットカード情報は、社内で一切保存をしておりません。
            </div>
            <div
              :style="{ backgroundImage: `url(${CardBrandsImage})` }"
              class="h-16 w-full bg-contain bg-center bg-no-repeat"
            />
            <div class="text-textBlack mb-1 mt-4 font-bold">
              銀行振込
            </div>
            <div class="text-grayAlpha80 mr-6 text-sm">
              請求書発行後、7日以内にお支払いください。※振込手数料はご負担ください。
            </div>
          </div>
        </SectionWrapper>
      </div>
      <div :class="`${componentName}__main`">
        <!-- 出願情報の確認 -->
        <SectionWrapper
          id="trademarkInfo"
          class="mb-6 sm:mb-0"
          :title="t('trademarkInfo')"
        >
          <TrademarkInfo />
        </SectionWrapper>
        <!-- 弁理士事務所ロゴ -->
        <div
          v-if="userData.attorneyOffice"
          class="border-grayAlpha10 border border-solid bg-white p-4 shadow-md"
        >
          <div class="text-textBlack mb-2 text-sm font-semibold">
            こちらの提携事務所が商標のご依頼を承ります！
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div
              v-if="userData.attorneyOffice.id === 1"
              :style="{ backgroundImage: `url(${HatsunaLogo})` }"
              class="border-borderGray h-16 flex-1 rounded-sm border border-solid bg-contain bg-center bg-no-repeat"
            />
            <div
              v-if="userData.attorneyOffice.id === 3"
              :style="{ backgroundImage: `url(${KXLogo})` }"
              class="border-borderGray h-16 flex-1 rounded-sm border border-solid bg-contain bg-center bg-no-repeat"
            />
            <div
              v-if="userData.attorneyOffice.id === 4"
              :style="{ backgroundImage: `url(${AuthenseLogo})` }"
              class="border-borderGray h-16 flex-1 rounded-sm border border-solid bg-contain bg-center bg-no-repeat"
            />
          </div>
        </div>
        <!-- オプション -->
        <SectionWrapper
          class="mb-6 sm:mb-0"
          :title="t('additonalOption')"
        >
          <AdditionalOptions />
        </SectionWrapper>
        <SectionWrapper
          class="mb-6 sm:mb-0"
          :title="t('applicantForm')"
        >
          <ApplicantForm />
        </SectionWrapper>
        <SectionWrapper
          class="mb-6 sm:mb-0"
          :title="t('applyInterview')"
        >
          <ApplyInterview />
        </SectionWrapper>
        <PriceConfirm class="mb-6 sm:mb-0" />
        <PaySteps
          class="my-6"
          :regist-period="registPeriod"
        />
        <AgreeTerms />
        <div class="mb-6 text-center font-bold">
          {{ t('note') }}
        </div>
        <div class="my-8 flex">
          <SecondaryButton
            class="mr-3 h-12 w-1/2"
            :title="t('back')"
            :disabled="false"
            @click="onBackButtonClick"
          />
          <PrimaryButton
            class="ml-3 h-12 w-1/2"
            :title="t('submit')"
            :disabled="false"
            @click="onSubmitButtonClick"
          />
        </div>
        <Modal
          :is-visible="showsPaymentModal"
          @close="showsPaymentModal = false"
        >
          <div class="px-4 pt-2 sm:w-[480px]">
            <div
              class="border-borderGray border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
            >
              {{ t('paymentModal') }}
            </div>
            <CreditCardPayment
              v-if="userData.attorneyOffice"
              class="py-6"
              :amount="applySubTotalFee"
              :stripe-account-id="userData.attorneyOffice.stripeAccountId"
              @complete="onPayComplete"
              @close="showsPaymentModal = false"
            />
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.RegistTrademarkPage {
  @include pc {
  }
  @include sp {
  }

  &__content {
    @include pc {
      display: grid;
      grid-template-columns: 338px 630px;
      column-gap: 32px;
    }
    @include sp {
    }
  }

  &__header {
    @include pc {
      grid-column-start: 1;
      grid-column-end: 3;
    }
    @include sp {
    }
  }

  &__aside {
    @include pc {
    }
    @include sp {
    }
  }

  &__priceAsideDescription {
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 8px;
    letter-spacing: 0.6px;
    line-height: 22px;
    white-space: pre-line;
    padding: 16px 0;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  &__paymentMethod {
    padding: 20px 26px;
  }

  &__paymentMethodLabel {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__paymentMethodDescription {
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 8px;
    line-height: 22px;
    white-space: pre-line;

    a {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  &__cardBrands {
    width: 100%;
  }

  &__main {
    @include pc {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 16px;
    }
    @include sp {
    }
  }
}
</style>
